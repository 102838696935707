module controllers {
    export module payment {
        interface IPaymentUpdateScope extends ng.IScope {
        }

        interface IPaymentUpdateParams extends ng.ui.IStateParamsService {
            payId: number;
        }

        export class paymentUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'paymentService',
                "documentTypeService",
                'bankService',
                'countryService',
                'userAccountService',
                "isInbound",
                'menuService',
            ];


            payId: number;
            IsInbound: boolean = false;

            isLoading: boolean = false;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            IsDisabled: boolean = false;

            payment: interfaces.payment.IPayment;
   
            //Status
            public payStatus: interfaces.master.IStatusData;

             apiInvList: uiGrid.IGridApi;
             apiLCList: uiGrid.IGridApi;

             bopList: Array<interfaces.applicationcore.IDropdownModel>;

             paymentHook: any;

             editDiscount: boolean = false;

            constructor(
                private $scope: IPaymentUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IPaymentUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private paymentService: interfaces.payment.IPaymentService,
                private documentTypeService: interfaces.documentManagement.IDocumentTypeService,
                public bankService: services.master.bankService,
                public countryService: interfaces.applicationcore.ICountryService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private isInbound: boolean,
                private menuService: interfaces.applicationcore.IMenuService,
            ) {

                this.payId = $stateParams.payId;
                this.IsInbound = isInbound;


                this.paymentHook = $transitions.onSuccess({
                    to: 'auth.SupplierPayments.Update',
                    from: 'auth.SupplierPayments.Update.**'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'pay.update'
                    }, () => {
                        return this.loadPayment(this.payId);
                    });
                });
                
                this.paymentHook = $transitions.onSuccess({
                    to: 'auth.CustomerPayments.Update',
                    from: 'auth.CustomerPayments.Update.**'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'pay.update'
                    }, () => {
                        return this.loadPayment(this.payId);
                    });
                });

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadPayment(this.payId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });

            }

            getEditDiscountRight() {
                this.editDiscount = false;
                return this.menuService.getGTSConnectMenuItem(694).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.editDiscount = true;
                    }
                }).$promise;
            }    

            loadPayment(Id: number) { 

                return this.paymentService.getPayment().query({ Id: Id }, (data: interfaces.payment.IPayment) => {

                    this.payment = data;

                    this.breadCrumbDesc = this.payment.PaymentCode;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwInvoices.data = this.payment.InvoiceList;
                        this.gvwLC.data = this.payment.LetterOfCreditList;
                        this.loadControls();
                        this.getEditDiscountRight();
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadControls() {
                var promises = [];
     
                promises.push(this.getDocuments(this.showAll));
                promises.push(this.loadStatus());
        
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.$q.all(promises);
                });
            }

            loadBanks() {
                return this.bankService.getDropdownList(this.payment.OwnerEntityId, "").query({
                }, (resultList) => {
                }).$promise; 
            }

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadBankChargesTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.BankCharges
                }, (resultList) => {
                       
                }).$promise;
            }

            loadUsers(searchText: string) {
                if (this.payment) {
                    return this.userAccountService.getDropdownList(searchText, this.payment.OwnerEntityId).query(
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadStatus() {
                this.payStatus = {
                    StatusName: this.payment.StatusName,
                    Id: this.payment.Id,
                    EntityId: this.payment.OwnerEntityId,
                    CurrentStatusId: this.payment.StatusId,
                    CurrentStatusComments: this.payment.StatusLastComments,
                    CurrentStatusStampDate: this.payment.StatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.payment.StatusLastUpdateStampFullName,
                    CurrentStatusType: this.isInbound ? Enum.EnumStatusType.SupplierPayment : Enum.EnumStatusType.CustomerPayment,
                    EnumStatusType: this.isInbound ? Enum.EnumStatusType.SupplierPayment : Enum.EnumStatusType.CustomerPayment,
                    EnumStatusAuthorisationType: this.isInbound ? Enum.EnumStatusAuthorisationType.SupplierPayment : Enum.EnumStatusAuthorisationType.CustomerPayment,
                    IsEditable: true
                };
            }

            updateStatus = (newStatusId: number, comments: string) => {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.payment.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                },
                    () => {
                        return this.savePayment().then(() => {
                            this.paymentService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                        this.selectedTab = 5;
                                        return this.loadPayment(this.payId);
                                    });
    
                                this.generalService.displayMessageHandler(data);
                            }, (failureData) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                        this.selectedTab = 5;
                                        return this.loadPayment(this.payId);
                                    });
                                    
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            });
                        }, () => {
                            return;
                        });
                    });
            };

            loadSBopCategoryList() {
                return this.paymentService.getBOPCategoriesForPayment(this.payment.OwnerEntityId, this.IsInbound).query({
                }, (resultList) => {
                        this.bopList = resultList;
                }).$promise;
            }

            RefreshBankingDetails_Click(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.RefreshBankingDetails().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                    return this.loadPayment(this.payId);
                                });
                            });
                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            }

            RefreshBankingDetails() {
                return this.paymentService.refreshBankingDetails().save({
                    paymentId: this.payment.Id
                }).$promise;
            };

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.payment.PaymentCode, systemType: this.IsInbound?Enum.EnumSystemType.SupplierPayment:Enum.EnumSystemType.CustomerPayments, ownerEntityId: this.payment.OwnerEntityId , showAll:showAll}, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                    if (this.documentRepository)
                        this.loadDocumentTypes();

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {

                if (this.isInbound) {
                    this.$state.go("auth.SupplierPayments.Update.Documents");
                } else {
                    this.$state.go("auth.CustomerPayments.Update.Documents");
                }

            }


            loadDocumentTypes() {

                return this.documentTypeService.getDocumentTypes().query({
                    repTypeId: this.documentRepository.RepositoryTypeId,
                    ownerEntityId: this.documentRepository.OwnerEntityId,
                    referenceNumber: this.payment.PaymentCode
                }, (documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    this.documentTypes = documentTypes;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }


            savePayment() {
               return this.paymentService.save().save(this.payment, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);


                    if (this.apiInvList) {
                        this.apiInvList.rowEdit.setRowsClean(this.payment.InvoiceList);
                    }

                    
                    if (this.apiLCList) {
                        this.apiLCList.rowEdit.setRowsClean(this.payment.LetterOfCreditList);
                    }
                 
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);

                }).$promise;
            };

             Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.savePayment().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                    return this.loadPayment(this.payId);
                                });
                            });
                        }
                    });
                });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.savePayment().then(() => {
                        this.Close();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");
                    });
                });
            }

            createNewVersion_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Create new version?").then((result: boolean) => {
                    if (result) {
                        this.createNewVersion(this.payment.Id,4,"Create New Version");
                    }
                });
            }

            createNewVersion(id: number, functionClassificationValueId: number, reason: string) {
                this.paymentService.CreateNewVersion().save(
                    { id, functionClassificationValueId, reason },
                    (result) => {
                        this.generalService.displayMessageHandler(result);
                        //Result
                        this.loadPayment(this.payId);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });               
            }

            Close() {
                this.$state.go("^");
            }

            RefreshInvoices()
            {
                if (this.isInbound) {
                    this.$state.go("auth.SupplierPayments.Update.Invoice", { payId: this.payment.Id, ownerEntityId: this.payment.OwnerEntityId, supplierId : this.payment.supplierId, customerId : this.payment.customerId, currencyId: this.payment.currencyId});
                } else {
                    this.$state.go("auth.CustomerPayments.Update.Invoice", { payId: this.payment.Id, ownerEntityId: this.payment.OwnerEntityId, supplierId : this.payment.supplierId, customerId : this.payment.customerId, currencyId: this.payment.currencyId});
                }
            }

            calculateEarlySettlementDiscount_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.calculateEarlySettlementDiscount().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                    return this.loadPayment(this.payId);
                                });
                            });
                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            }            

            calculateEarlySettlementDiscount() {
                return this.paymentService.calculateEarlySettlementDiscount().save({
                    paymentId: this.payId
                }).$promise;
            };

            

            IsAdvancedPaymentChange()
            {
                if (!this.payment.IsAdvancedPayment && this.payment.LockdownType<3)
                {
                    this.payment.IsInvoiceTabLocked = false;
                }
                else
                {
                    this.payment.IsInvoiceTabLocked = true;
                }
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {                
                this.apiInvList = gridApi;                
            }

            showFECAllocations(){
                this.displayFECAllocations(this.payment.OwnerEntityId,this.payment.Id).then((result: interfaces.payment.IFECAllocationDisplay) => {
                    if (result) {
                        this.payment.FECCode = result.FECCode;
                        this.payment.FECId = result.fecId;
                        this.payment.FECAllocationId = result.falId;
                    }
                });      
            } 

            displayFECAllocations(ownerEntityId:number,paymentId : number)
            {
                     //Get delivery status
                     return this.$uibModal.open({
                        animation: true,
                        template: `
                            <div class="modal-content" >
                                <form name="payInvoice">
                                    <div class="gts-padding">
                                        <fieldset id="payInvoice">
                                        <legend>
                                        FEC Allocation
                                        </legend>                                            
                                        <div class="vertical-align">
                                            <div class="col-md-12">
                                                <div id="gridTest" ui-grid="gvwFEC" ui-grid-resize-columns ui-grid-pagination ui-grid-cellNav class="gridSmallScreen"
                                                        ng-if="gvwFEC">
                                                        <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                            <div class="loadingGrid" ng-show="IsLoading">
                                                                <i class="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        </fieldset>
                                    </div>
                                </form>
                            </div>
                        `
                        ,
                        controller: ($scope,  $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, paymentService: interfaces.payment.IPaymentService) => {
                                $scope.ownerEntityId = ownerEntityId;
                                $scope.paymentId = paymentId;
                            $scope.cancel = () => {
                                $uibModalInstance.dismiss();
                            }
    
                            $scope.ok = (entity: interfaces.payment.IFECAllocationDisplay) => {
                                $uibModalInstance.close(entity);
                            };   

                            $scope.gvwFEC = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: false,
                            paginationPageSizes: [15, 30, 45, 60],
                            paginationPageSize: 15,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                paymentService.getFECsForPayment($scope.ownerEntityId, $scope.paymentId).query((result: interfaces.payment.IFECAllocationDisplay) => {
                                    $scope.gvwFEC.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [
                                {
                                    name: "EDIT",
                                    displayName: "",
                                    field: "Id",
                                    cellTemplate: `
                                    <div class="GridButton"> 
                                        <button type="button" ng-click="grid.appScope.ok(row.entity)" class="btn  btn-primary btn-sm">
                                            Select
                                        </button>
                                    </div>`,
                                    enableSorting: false,
                                    enableColumnMenu: false,
                                    enableCellEdit: false,
                                    width: 54,
                                    enableCellEditOnFocus: false
                                },                              
                                {
                                name: "Code",
                                displayName: "Code",
                                field: "FECCode",
                                type: "string",
                                width: 120,
                            }, {
                                name: "FECNumber",
                                displayName: "Number",
                                field: "FECNumber",
                                type: "string",
                                width: 140,
                            },  {
                                name: "MaturityDate",
                                displayName: "Maturity Date",
                                field: "MaturityDate",
                                type: "moment.Moment",
                                enableFiltering: false,
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                                width: 120,
                            },{
                                name: "Balance",
                                displayName: "Balance",
                                field: "Balance",
                                type: "string",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col) | number:2}}</div>',
                                width: 140,
                            }, {
                                name: "BankName",
                                displayName: "Bank",
                                field: "BankName",
                                type: "string",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                width: 140,
                            }]
                        };                    
                        },
                        controllerAs: "payFECCtrl",
                        size: "lg",
                        resolve: {
                            
                        }
                    }).result;
                }        

            


            editPaymentInvoice(entity: interfaces.payment.IPaymentInvoiceLinesDisplay) {
      
                this.displayPaymentInvoice(entity,this.isInbound,this.editDiscount).then((result: interfaces.payment.IPaymentInvoiceLinesDisplay) => {
                    if (result) {

                        entity.PaymentAmountPaid = result.PaymentAmountPaid;
                        entity.IsFinalAmountPaid = result.IsFinalAmountPaid;
                        entity.ReasonForDifference = result.ReasonForDifference;
                        
                        if(this.apiInvList){
                            this.apiInvList.rowEdit.setRowsDirty([entity]);
                        }
                        
                       this.payment.PaymentAmount =  this.payment.InvoiceList.reduce((sum, current) => sum + current.PaymentAmountPaid, 0);

                        this.Save();
                    }
                });      
            }

                displayPaymentInvoice(invoice : interfaces.payment.IPaymentInvoiceLinesDisplay, isInbound: boolean,editDiscount: boolean) {
                    //Get delivery status
                    return this.$uibModal.open({
                        animation: true,
                        template: `
                            <div class="modal-content" >
                                <form name="payInvoice">
                                    <div class="gts-padding">
                                        <fieldset id="payInvoice">
                                        <legend>
                                        Payment to Commercial Invoice
                                        </legend>   
                                        <gts-message-display messages="Messages"></gts-message-display>                                         
                                                <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Amount Owing</label>
                                                </div>
                                                <div class="col-md-8">
                                                    {{Invoice.AmountOwing}}
                                                </div>
                                            </div>
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Amount Paid</label>
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="input-group input-group-sm">
                                                        <input type="number" ignore-wheel name="PaymentAmountPaid" ng-change="doValidation()"
                                                            ng-model="Invoice.PaymentAmountPaid" class="form-control"
                                                            step="0.0000000000001" />
                                                        <span class="input-group-addon">{{Invoice.CurrencyCode}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row vertical-align" ng-if="isInbound">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Discount Amount</label>
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="input-group input-group-sm">
                                                        <input type="number" ignore-wheel name="DiscountAmount" ng-change="discountChanged()"
                                                            ng-model="Invoice.DiscountAmount" class="form-control" ng-disabled ="!editDiscount"
                                                            step="0.0000000000001" />
                                                        <span class="input-group-addon">{{Invoice.CurrencyCode}}</span>
                                                    </p>
                                                </div>
                                            </div>                                            
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Is Final</label>
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"  ng-change="doValidation()"
                                                            ng-model="Invoice.IsFinalAmountPaid" 
                                                            name="IsFinalAmountPaid">
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row vertical-align">
                                                        <div class="col-md-4"> <label>Reason for difference</label></div>
                                                            <div class="col-md-8">
                                                                <p class="input-group-sm">
                                                                    <textarea id="docNotes" ng-disabled="!isReasonEnabled" class="form-control" ng-model="Invoice.ReasonForDifference" rows="25"  ng-change="doValidation()"></textarea>
                                                                </p>
                                                        </div>                                               
                                            </div>                                            
                                                <div class="gts-padding pull-right">                                               
                                                        <button type="button" class="btn btn-primary" ng-click="ok()" ng-disabled="!isOKEnabled">Ok</button>
                                                        <button type="button" class="btn btn-info" ng-click="cancel()">Cancel</button>
                                                </div>                                       
                                        </fieldset>
                                    </div>
                                </form>
                            </div>
                        `
                        ,
                        controller: ($scope,  $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                            
                            $scope.Invoice = invoice;
                            $scope.isInbound = isInbound;
                            $scope.editDiscount = editDiscount;

                            $scope.DiscountChanged = false;


                            $scope.cancel = () => {
                                $uibModalInstance.dismiss();
                            }

                            $scope.isReasonEnabled = true;
                            $scope.isOKEnabled = false;

                            $scope.discountChanged = () => {
                                $scope.DiscountChanged = true;
                                $scope.doValidation();
                            }

                            $scope.doValidation = () => {
                                
                                if ($scope.Invoice.PaymentAmountPaid === $scope.Invoice.AmountOwing )
                                {
                                    $scope.Invoice.IsFinalAmountPaid = true;
                                    $scope.isReasonEnabled = false;
                                    $scope.Invoice.ReasonForDifference = "";
                                    $scope.isOKEnabled = true;
                                }
                                else if ($scope.Invoice.IsFinalAmountPaid === false )
                                {
                                    $scope.isReasonEnabled = false;
                                    $scope.Invoice.ReasonForDifference = "";
                                    $scope.isOKEnabled = true;
                                }
                                else if ($scope.Invoice.IsFinalAmountPaid === true && ($scope.Invoice.ReasonForDifference === "" || !$scope.Invoice.ReasonForDifference) )
                                {
                                    $scope.isReasonEnabled = true;
                                    $scope.isOKEnabled = false;
                                }     
                                else {
                                    $scope.isOKEnabled = true;
                                }

                                if ($scope.DiscountChanged === true && ($scope.Invoice.ReasonForDifference === "" || !$scope.Invoice.ReasonForDifference) ){
                                    $scope.isReasonEnabled = true;
                                    $scope.isOKEnabled = false;
                                } 

                            }
    
                            $scope.ok = () => {
                                
                                if ($scope.Invoice.PaymentAmountPaid === $scope.Invoice.AmountOwing )
                                {
                                    $scope.Invoice.IsFinalAmountPaid = true;
                                }

                                if ($scope.Invoice.PaymentAmountPaid > $scope.Invoice.AmountOwing && !$scope.Invoice.IsFinalAmountPaid)
                                {
                                    this.generalService.displayMessage("Over allocation to an invoice that is not marked as 'Is Final' is not allowed.", Enum.EnumMessageType.Warning);
                                    return false;
                                }
                                else
                                {
                                    this.$rootScope.Messages = [];
                                }
                              
                                $uibModalInstance.close($scope.Invoice);
                            };                       
                        },
                        controllerAs: "payInvoiceCtrl",
                        size: "md",
                        resolve: {
                            
                        }
                    }).result;
                }        


            

             //Build UIGrid
             gvwInvoices: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.paymentUpdateCtrl.editPaymentInvoice(row.entity)" class="btn btn-default btn-sm" ng-disabled="grid.appScope.paymentUpdateCtrl.payment.LockdownType >= 3 || grid.appScope.paymentUpdateCtrl.payment.IsInvoiceTabLocked">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: "InvoiceNumber",
                    displayName: "Invoice Number",
                    field: "InvoiceNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    
                },
                {
                    name: "ConsignmentNumber",
                    displayName: "Consignment",
                    field: "ConsignmentNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    
                },
                {
                    name: "TermsOfPaymentCode",
                    displayName: "Terms of Payment",
                    field: "TermsOfPaymentCode",
                    width: 130,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "TotalInvoiceValue",
                    displayName: "Invoice Value",
                    field: "TotalInvoiceValue",
                    width: 100,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },{
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "IsPaymentDue",
                    displayName: "Is Due",
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100,
                    cellTemplate: `<input type="checkbox"  ng-disabled="true" ng-model="row.entity.IsPaymentDue">`                    
                },
                {
                    name: "PaymentDueDate",
                    displayName: "Payment Due Date",
                    field: "PaymentDueDate",
                    width: 160,
                    cellFilter: "date:'yyyy/MM/dd'",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "DiscountDate",
                    displayName: "Discount Date",
                    field: "DiscountDate",
                    width: 160,
                    visible: this.isInbound,
                    cellFilter: "date:'yyyy/MM/dd'",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "AmountPaid",
                    displayName: "Amount Paid",
                    field: "PaymentAmountPaid",
                    width: 120,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                 {
                    name: "DiscountAmount",
                    displayName: "Discount Amount",
                    field: "DiscountAmount",
                    width: 120,
                    visible: this.isInbound,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },                
                {
                    name: "AmountOwing",
                    displayName: "Amount Owing",
                    field: "AmountOwing",
                    width: 120,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }]
            };


                gvwLC: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.apiLCList = gridApi;       },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "ApplicationReference",
                    displayName: "Application Ref.",
                    field: "ApplicationReference",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    
                },{
                    name: "CreateDate",
                    displayName: "Create Date",
                    field: "CreateDate",
                    width: 200,
                    cellFilter: "date:'yyyy/MM/dd'",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },{
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },{
                    name: "ExpirationDate",
                    displayName: "Expiration Date",
                    field: "ExpirationDate",
                    width: 200,
                    cellFilter: "date:'yyyy/MM/dd'",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },{
                    name: "LCBankNumber",
                    displayName: "LC Bank Number",
                    field: "LCBankNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }]
            };


        };

        angular.module("app").controller("paymentUpdateCtrl", controllers.payment.paymentUpdateCtrl);
    }
}
